import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions(); // Initialize Firebase Functions
interface CreateUserResponse {
   userId: string;
   message: string;
 }

export const createUserFn = httpsCallable<{}, CreateUserResponse>(functions, 'createUser');
export const updateUserFn = httpsCallable(functions, "updateUser"); 
export const deleteUserFn = httpsCallable(functions, "deleteUser");
