import React from "react";
import { ROUTES } from "constant";

import { Box, Button, Grid, InputLabel, Link, TextField, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";

type Props = {

};

export const EnterCodeForm = (props: Props) => {
  const navigate = useNavigate();

  const handleNavigateDashboard = () => navigate(ROUTES.LOCATION);

  return (
    <React.Fragment>


      <form >
        <Grid mt={5} container spacing={3}>
          <Grid item xs={12}>

            <Box
              sx={{
                fontWeight: "600",
                fontSize: "23px",
                color: "white",
                marginBottom: '0px',
                paddingBottom: '0px',
              }}
            >
              Enter Code
            </Box>
            <TextField
              type="number"
              variant="outlined"
              fullWidth
              placeholder="000-000"
              sx={{
                backgroundColor: 'white',
                borderRadius: '8px',

                '& .MuiInputBase-input::placeholder': {
                  color: 'black',
                  opacity: 1,
                },
                // '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                //   border: "2px solid #87C9E9",
                //   boxShadow: "0px 0px 0px 4px #97CAD84D",
                // },
              }}
            />

          </Grid>



          <Grid mt={3} item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                backgroundColor: "#BDE047",
                borderRadius: "8px",
                color: "black",
                '&:hover': {
                  backgroundColor: "#BDE047",
                },
              }}
              onClick={handleNavigateDashboard}
            >
              Submit
            </Button>
          </Grid>
          <Grid item xs={12} textAlign="right" sx={{ color: "white" }}>
            <Link style={{ color: 'white', textDecoration: 'none' }}>
              Resend Code
            </Link>
          </Grid>

        </Grid>
      </form>
    </React.Fragment>
  );

};
