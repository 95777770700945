import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, TextField } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "constant";
import * as yup from "yup";
import { AuthService } from "services/Auth.Services";
import { useNotification } from "../../../../context/notificationContext";
import LoadingButton from "@mui/lab/LoadingButton";

export const emailSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
});

interface IIdentifyUserFormType {
  email: string;
}

type Props = {};

export const IdentifyUserForm = (props: Props) => {
  const navigate = useNavigate();
  const { setAlert } = useNotification();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IIdentifyUserFormType>({
    mode: "onChange",
    resolver: yupResolver(emailSchema),
  });

  const [isLoading, setIsLoading] = React.useState(false);

  const formSubmitHandler: SubmitHandler<IIdentifyUserFormType> = async (
    values
  ) => {
    setIsLoading(true);
    try {
      await AuthService.resetPassword(values.email);

      console.log(values.email, "Password reset email sent!");
      setAlert({
        show: true,
        variant: "success",
        message: "Password reset email sent!",
      });
    } catch (error: any) {
      setAlert({
        show: true,
        message: error.message || "Error sending reset email",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <form>
        <Grid mt={5} container spacing={2}>
          <Grid item xs={12}>
            <TextField
              {...register("email")}
              error={!!errors.email}
              // helperText={errors.email?.message}
              type="email"
              variant="outlined"
              fullWidth
              placeholder="Enter your email"
              sx={{
                border: "1px solid black",
                borderRadius: "8px",
                marginTop: "0px",
                "& .MuiInputBase-input::placeholder": {
                  color: "black",
                  opacity: 1,
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "8px",
                  },
                },
              }}
            />

            {errors.email && (
              <Box sx={{ color: "red", mt: 1 }}>{errors.email.message}</Box>
            )}
          </Grid>

          <Grid mt={3} item xs={12}>
            <LoadingButton
              loading={isLoading}
              onClick={handleSubmit(formSubmitHandler)}
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                backgroundColor: "#0A9959",
                borderRadius: "8px",
                color: "white",
                "&:hover": {
                  backgroundColor: "#0A9959",
                },
              }}
            >
              Reset Password
            </LoadingButton>
          </Grid>

          <Grid item xs={12} textAlign="right">
            <Link to={ROUTES.LOGIN} style={styles.link}>
              Login here
            </Link>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

const styles = {
  link: {
    color: "#343434",
    textDecoration: "underline ",
    fontWeight: "400",
    fontSize: "16px",
  },
};
