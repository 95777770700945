import React, { useState } from "react";
import {
  Box,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableSortLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Location } from "models/schema";
import { ConditionalRate } from "models/schema";
import { useAuthContext } from "context/AuthContext";
type Order = "asc" | "desc";

// const LocationTable: React.FC = () => {
// const LocationTable: React.FC<{ handleEditLocation: (locItem: Song) => void }> = ({ handleEditLocation }) => {
const LocationTable: React.FC<{
  handleEditLocation: (locItem: Location) => void;
  getTableData: Location[]; // Change this to Location[] if you're passing data directly
}> = ({ handleEditLocation, getTableData }) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Location>("id");
  const { user } = useAuthContext();
  // Provide table data to parent
  //   React.useEffect(() => {
  //     getTableData(getTableData); // Send data to parent component
  //   }, [getTableData]);

  const handleRequestSort = (property: keyof Location) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = [...getTableData].sort((a, b) => {
    let aValue: any = a[orderBy];
    let bValue: any = b[orderBy];

    // Handle sorting for specific fields
    if (orderBy === "locationRc") {
      aValue = parseInt(a.locationRc, 10) || 0; // Ensure numeric comparison
      bValue = parseInt(b.locationRc, 10) || 0;
    }

    if (orderBy === "locationName" || orderBy === "standName") {
      aValue = a[orderBy]?.toLowerCase() || ""; // Ensure case-insensitive string comparison
      bValue = b[orderBy]?.toLowerCase() || "";
    }

    if (orderBy === "standNumber") {
      aValue = parseInt(a.standNumber, 10) || 0; // Ensure numeric comparison
      bValue = parseInt(b.standNumber, 10) || 0;
    }

    // Numeric comparison
    if (typeof aValue === "number" && typeof bValue === "number") {
      return order === "asc" ? aValue - bValue : bValue - aValue;
    }

    // String comparison (default case)
    if (typeof aValue === "string" && typeof bValue === "string") {
      return order === "asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    return 0; // Default case for unmatched types
  });

  return (
    <React.Fragment>
      <Box flex={1} overflow={"auto"} zIndex={10} px={{ xs: "0px", md: 4 }}>
        <TableContainer component={Paper} sx={{ flex: 1 }}>
          <Table className="user__table" sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow sx={{ bgcolor: "#EAEAEA" }}>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "locationRc"}
                    direction={orderBy === "locationRc" ? order : "asc"}
                    onClick={() => handleRequestSort("locationRc")}
                  >
                    Location RC
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "locationName"}
                    direction={orderBy === "locationName" ? order : "asc"}
                    onClick={() => handleRequestSort("locationName")}
                  >
                    Location Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "standNumber"}
                    direction={orderBy === "standNumber" ? order : "asc"}
                    onClick={() => handleRequestSort("standNumber")}
                  >
                    Stand #
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "standName"}
                    direction={orderBy === "standName" ? order : "asc"}
                    onClick={() => handleRequestSort("standName")}
                  >
                    Stand Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "rates"}
                    direction={orderBy === "rates" ? order : "asc"}
                    // onClick={() => handleRequestSort("rates")}
                  >
                    Rates
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "conditionalRates"}
                    direction={orderBy === "conditionalRates" ? order : "asc"}
                    // onClick={() => handleRequestSort("conditionalRates")}
                  >
                    Conditional Rates
                  </TableSortLabel>
                </TableCell>
                {user?.role !== "Associate" && <TableCell>Edit</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((locItem) => (
                <TableRow key={locItem.id}>
                  <TableCell>{locItem.locationRc}</TableCell>
                  <TableCell>{locItem.locationName}</TableCell>
                  <TableCell>{locItem.standNumber}</TableCell>
                  <TableCell>{locItem.standName}</TableCell>
                  <TableCell>
                    {locItem.rates.map((rate: number, index: number) => (
                      <span key={index}>
                        ${rate}
                        {index !== locItem.rates.length - 1 && ","}
                      </span>
                    ))}
                  </TableCell>
                  <TableCell>
                    {locItem.conditionalRates.map(
                      (rateObj: ConditionalRate, index: number) => (
                        <div key={index}>
                          <span>
                            ${rateObj.rate1} & ${rateObj.rate2}
                            {index !== locItem.conditionalRates.length - 1 &&
                              ","}
                          </span>
                        </div>
                      )
                    )}
                  </TableCell>
                  {user?.role !== "Associate" && (
                    <TableCell sx={{ cursor: "pointer" }}>
                      <Box
                        onClick={() => handleEditLocation(locItem)}
                        component="img"
                        src="assetsOaks/icon/edit.svg"
                        alt="export"
                      ></Box>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  );
};

export default LocationTable;
