import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { AppLayout } from "layout";
import { CBox } from "components";
import { RevenueData } from "modules/Revenue/components";
import { LocationService } from "services/Location.Services";
import { FirestoreError } from "firebase/firestore";
import { Location, GroupedLocation } from "models/schema";
import { useAuthContext } from "context/AuthContext";
// Run effect only on mount and unmount
type Props = {};

const RevenueContainer = (props: Props) => {
  const [locations, setLocations] = useState<GroupedLocation[]>([]); // Local state to store locations
  const [error, setError] = useState<FirestoreError | null>(null); // State to store errors
  const [loading, setLoading] = useState(true); // Loading state to handle loader
  const { user } = useAuthContext();
  const locationIds = user?.locationIds || null;
  const role = user?.role || "";

  useEffect(() => {
    // Data callback to handle real-time location data
    const dataCallback = (locationList: GroupedLocation[]) => {
      setLocations(locationList); // Update the state with real-time data
      setLoading(false); // Hide loader once data is loaded
    };

    // Error callback to handle any Firestore errors
    const errorCallback = (error: FirestoreError) => {
      setError(error); // Set error state
      setLoading(false); // Hide loader in case of an error
    };
    console.log("locationIds", locationIds);

    setLoading(true);
    // console.log("locationIds", user);
    // Pass both locationIds and role to the service
    const unsubscribe = LocationService.getGroupedLocations(
      locationIds,
      role,
      dataCallback,
      errorCallback
    );

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [user]);

  return (
    <AppLayout title="Revenue Report">
      <CBox col height={"100%"} gap={5} px={4}>
        {/* Show loader only in the main content area */}
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          <RevenueData locations={locations} />
        )}
      </CBox>
    </AppLayout>

    // <AppLayout title="Revenue Report">
    //   <CBox col height={"100%"} gap={5}>
    //     <RevenueData locations={locations} />
    //   </CBox>
    // </AppLayout>
  );
};

export default RevenueContainer;
