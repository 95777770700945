import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { ProfileService } from "services/Profile.Services";
import { LocationService } from "services/Location.Services";
import { Admin, Location } from "models/schema";
import { FirestoreError } from "firebase/firestore";
import { AppLayout } from "layout";
import { CBox } from "components";
import { AdminAccountData } from "modules/AdminAccounts/components";
import { useAuthContext } from "context/AuthContext";
const AdminAccountContainer = () => {
  const [admins, setAdmins] = useState<Admin[]>([]); // Local state to store admins
  const [locations, setLocations] = useState<Location[]>([]); // Local state to store locations
  const [error, setError] = useState<FirestoreError | null>(null); // State to store errors
  const [loading, setLoading] = useState(true); // Loading state to handle loading UI
  const { user } = useAuthContext();
  useEffect(() => {
    const adminDataCallback = (adminList: Admin[]) => {
      setAdmins(adminList);
      setLoading(false); // Data loaded, hide the loader
    };

    const locationDataCallback = (locationList: Location[]) => {
      setLocations(locationList);
      // setLoading(false); // Data loaded, hide the loader
    };

    const errorCallback = (error: FirestoreError) => {
      setError(error);
      setLoading(false); // Error occurred, hide the loader
    };

    setLoading(true); // Show loader when data request starts

    const unsubscribeAdmins = ProfileService.getAllAdminsWithLocations(
      adminDataCallback,
      errorCallback
    );

    const locationIds = user?.locationIds || null;
    const role = user?.role || "";



    const unsubscribeLocations = LocationService.getAllLocations2(
      locationIds,
      role,
      locationDataCallback,
      errorCallback
    );

    return () => {
      unsubscribeAdmins();
      unsubscribeLocations();
    };
  }, [user]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <AppLayout title="Users">
      <CBox col height={"100%"} gap={5} px={4}>
        {/* Show loader only in the main content area */}
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          <AdminAccountData admins={admins} locations={locations} />
        )}
      </CBox>
    </AppLayout>
  );
};

export default AdminAccountContainer;
