import React, { useState, ChangeEvent, FC } from 'react';
import {
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   Button,
   MenuItem,
   Select,
   InputLabel,
   FormControl,
   SelectChangeEvent,
   Box,
   Typography,
   IconButton,
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { BlissTipsCard, CBox, CustomDropdown, CustomTextField, PhoneNumberInput } from 'components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import AddProvider from '../AddProviderDialog';

interface UploadSongsDialogProps {
   open: boolean;
   onClose: () => void;
   selectedFiles: File[];
   setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const schema = yup.object().shape({
   accountType: yup.string().required('Account Type is required'),
   fullName: yup
      .string()
      .required('Full name is required')
      .min(3, 'Full name must be at least 3 characters'),
   email: yup.string().required('Email is required').email('Email must be a valid email address'),
   phoneNumber: yup
      .string()
      .required('Phone number is required')
      .test('isValidPhoneNumber', 'Invalid phone number', (value) => {
         return typeof value == 'string' && isValidPhoneNumber(value);
      }),
});
const UploadSongsDialog: FC<UploadSongsDialogProps> = ({
   open,
   onClose,
   selectedFiles,
   setSelectedFiles,
}) => {
   const methods = useForm({
      resolver: yupResolver(schema),
      defaultValues: {
         accountType: '',
         email: '',
         fullName: '',
         phoneNumber: '',
      },
   });
   const { handleSubmit } = methods;

   const [addProviderModal, setAddProviderModal] = useState<boolean>(false);

   const handleAddSongs = (data: any) => {
      console.log(data);
      onClose();
   };

   const handleRemoveFile = (index: number) => {
      setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
   };

   return (
      <Dialog
         open={open}
         onClose={onClose}
         fullWidth
         maxWidth={'lg'}
         sx={{
            '& .MuiPaper-root': {
               borderRadius: 2,
               padding: '30px 8px 24px 8px',
            },
         }}>
         <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleAddSongs)}>
               <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <CBox wrp gap={8}>
                     <CBox col gap={0.5} flex={1}>
                        <CustomDropdown
                           name='provider'
                           label='Provider'
                           options={[
                              { name: 'Moderator', value: 'moderator' },
                              { name: 'Admin', value: 'admin' },
                           ]}
                        />

                        <Typography
                           textAlign={'end'}
                           variant='h5_bold'
                           sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                           onClick={() => setAddProviderModal(true)}>
                           Add New Provider
                        </Typography>
                     </CBox>
                     <Box flex={1}>
                        <CustomDropdown
                           name='bpm'
                           label='Enter BPM'
                           options={[
                              { name: 'Moderator', value: 'moderator' },
                              { name: 'Admin', value: 'admin' },
                           ]}
                        />
                     </Box>
                  </CBox>
                  <CBox wrp>
                     {selectedFiles.map((song, i) => {
                        return (
                           <BlissTipsCard
                              key={i}
                              text={song.name}
                              onDelete={() => handleRemoveFile(i)}
                           />
                        );
                     })}
                  </CBox>
               </DialogContent>
               <DialogActions sx={{ justifyContent: 'center' }}>
                  <Button type='submit' variant='contained' color='primary' sx={{ width: '20%' }}>
                     Upload
                  </Button>
               </DialogActions>
            </form>
         </FormProvider>
         <AddProvider open={addProviderModal} onClose={() => setAddProviderModal(false)} />
      </Dialog>
   );
};

export default UploadSongsDialog;
