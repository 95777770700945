import React, { useState, ChangeEvent, FC } from 'react';
import {
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   Button,
   MenuItem,
   Select,
   InputLabel,
   FormControl,
   SelectChangeEvent,
   Box,
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { CustomDropdown, CustomTextField, PhoneNumberInput } from 'components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

interface AddProviderProps {
   open: boolean;
   onClose: () => void;
}

const schema = yup.object().shape({
   accountType: yup.string().required('Account Type is required'),
   fullName: yup
      .string()
      .required('Full name is required')
      .min(3, 'Full name must be at least 3 characters'),
   email: yup.string().required('Email is required').email('Email must be a valid email address'),
   phoneNumber: yup
      .string()
      .required('Phone number is required')
      .test('isValidPhoneNumber', 'Invalid phone number', (value) => {
         return typeof value == 'string' && isValidPhoneNumber(value);
      }),
});
const AddProvider: FC<AddProviderProps> = ({ open, onClose }) => {
   const methods = useForm({
      resolver: yupResolver(schema),
      defaultValues: {
         accountType: '',
         email: '',
         fullName: '',
         phoneNumber: '',
      },
   });
   const { handleSubmit } = methods;

   const handleAddProvider = (data: any) => {
      console.log(data);
      onClose();
   };

   return (
      <Dialog
         open={open}
         onClose={onClose}
         sx={{
            '& .MuiPaper-root': {
               borderRadius: 2,
               padding: '16px 8px 16px 8px',
            },
         }}>
         <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleAddProvider)}>
               <DialogContent>
                  <CustomTextField name='providerName' label='Enter Provider Name' />
               </DialogContent>
               <DialogActions sx={{ justifyContent: 'center' }}>
                  <Button type='submit' variant='contained' color='primary' sx={{ width: '50%' }}>
                     Save
                  </Button>
               </DialogActions>
            </form>
         </FormProvider>
      </Dialog>
   );
};

export default AddProvider;
