import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

interface CommonPopupProps {
    open: boolean;
    title: string;
    message?: string;
    messageText?: string;
    confirmText: string;
    cancelText?: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const CommonPopup: React.FC<CommonPopupProps> = ({ open, title, message, messageText, confirmText, cancelText, onConfirm, onCancel }) => {
    return (
        <Dialog open={open} onClose={onCancel} PaperProps={{ sx: { width: "429px", padding: "24px", borderRadius: "12px" } }}>
            <DialogTitle sx={styleSheet.title}>{title}</DialogTitle>
            <DialogContent>
                {message && <Typography sx={styleSheet.message}>{message}</Typography>}
                {messageText && <Typography sx={styleSheet.messageText}>{messageText}</Typography>}
            </DialogContent>
            <DialogActions sx={styleSheet.actions}>
                <Button onClick={onConfirm} variant="contained" sx={styleSheet.confirmButton}>
                    {confirmText}
                </Button>
                {cancelText && (
                    <Button onClick={onCancel} variant="outlined" sx={styleSheet.cancelButton}>
                        {cancelText}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default CommonPopup;

const styleSheet = {
    title: {
        fontSize: "22px",
        fontWeight: 400,
        textAlign: "center",
        color: "#000000",
    },
    message: {
        fontSize: "18px",
        textAlign: "center",
        fontWeight: 400,
        color: "#000000",
    },
    messageText: {
        fontSize: "18px",
        textAlign: "center",
        fontWeight: 400,
        marginBottom: "20px",
    },
    actions: {
        display: 'flex',
        flexDirection: 'column',
        gap: "25px",
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: "20px",
    },
    confirmButton: {
        backgroundColor: "#0A9959",
        color: "#fff",
        padding: "12px 24px",
        // width: "197px",
        borderRadius: "8px",
        '&:hover': {
            backgroundColor: "#088C4D",
        },
    },
    cancelButton: {
        borderColor: "#DCDCDC",
        color: "#333",
        padding: "12px 24px",
        // width: "128px",
        borderRadius: "8px",
        fontSize: "14px",
        fontWeight: 800,
    },
};
