import { RevenueContainer } from 'modules/Revenue'
import React from 'react'

type Props = {}

const RevenueReport = (props: Props) => {
  return (
    <RevenueContainer />
  )
}

export default RevenueReport