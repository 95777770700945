import React from 'react';
import { Box, Grid } from '@mui/material';
import { AppLayout } from 'layout';
import { CBox } from 'components';
import { CreateEntryData } from 'modules/Entries/components';

type Props = {};

const CreateEntryContainer = (props: Props) => {
    return (
        <AppLayout
            title='Entries'
        >
            <CBox col height={'100%'} gap={5}>
                <CreateEntryData />
            </CBox>
        </AppLayout>
    );
};

export default CreateEntryContainer;
