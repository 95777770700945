// import axios from 'axios';
import { auth, firebaseError, firestore } from "libs/firebase/@firebase";
import { ERRORS } from "constant";
import {
  signOut,
  deleteUser,
  updateEmail,
  updatePassword,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  doc,
  where,
  query,
  setDoc,
  getDoc,
  addDoc,
  getDocs,
  Timestamp,
  deleteDoc,
  updateDoc,
  collection,
  onSnapshot,
} from "firebase/firestore";
import { throwFirebaseException } from "utils";
import { CustomError } from "utils/CustomError";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { EmailService } from './Email.Services';
// import FIREBASE_COLLECTIONS from '@Constants/firebaseCollections';
import { ProfileService } from "services/Profile.Services";
import {
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";

type LoginValues = {
  email: string;
  password: string;
  rememberMe: boolean;
};

class Auth {
  login = async (
    values: LoginValues = { email: "", password: "", rememberMe: false }
  ): Promise<string> => {
    const { email, password, rememberMe } = values;

    return new Promise<string>(async (resolve, reject) => {
      try {
        // Set session persistence based on rememberMe
        const persistenceMode = rememberMe
          ? browserLocalPersistence // Session persists across browser restarts
          : browserSessionPersistence; // Session only persists in the current tab

        await setPersistence(auth, persistenceMode); // Set Firebase persistence mode

        // Sign in the user with email and password
        await signInWithEmailAndPassword(auth, email, password);
        const users = await ProfileService.getAdminByEmail(values.email);
        if (users.length > 0 && users[0].status === "Inactive") {
          //logout user if status is inactive
          await signOut(auth);
          reject({
            code: "auth/user-inactive",
            message: "Your account is inactive. Please contact the admin.",
          });
        } else {
          resolve("User signed in successfully!");
        }
      } catch (error: any) {
        if (error instanceof firebaseError) {
          reject({
            code: error.code,
            message: throwFirebaseException(error.code),
          });
        } else if (error instanceof CustomError) {
          reject(error.toJSON());
        } else {
          reject(ERRORS.UNKNOWN_ERROR);
        }
      }
    });
  };

  logOut = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        await signOut(auth);
        // const navigate = useNavigate();
        // navigate("/login");

        resolve("signed out!");
      } catch (error) {
        reject("sign out failed");
      }
    });
  };

  getUserByEmail = async (email: string) => {
    try {
      const functionUrl = "https://get-user-by-email-rekmjjk74q-uc.a.run.app";

      const requestData = {
        email: email,
      };

      const response = await axios.post(functionUrl, requestData);

      console.log("Function response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error calling Firebase Cloud Function:", error);
      throw error;
    }
  };

  resetPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
      return "Password reset email sent!";
    } catch (error: any) {
      if (error.code) {
        throw new Error(error.message);
      }
      throw new Error("Unknown error occurred");
    }
  };
}

const AuthService = new Auth();

export { AuthService };
