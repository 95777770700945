export const ROUTES = {
  LOGIN: "/login",
  FORGOT_PASSWORDS: "/forgot-password",
  IDENTIFY_USER: "/identify-user",
  ENTER_CODE: "/enter-code",
  DASHBOARD: "/dashboard",
  LIBRARY: "/library",

  MANAGE_USER: "/manage-user",
  BLISS_TIPS: "/bliss-tips",
  LEGAL: "/legal",
  ADMIN_ACCOUNTS: "/admin-accounts",

  USER_PROFILE: "/user-profile",

  /// Oaks Routes ///
  LOCATION: "/",
  REVENUE_REPORT: "/revenue-report",
  ENTRIES: "/entries",
  ENTRIES_CREATE: "/create-entry",
};

export default ROUTES;
