import { AdminAccountContainer } from 'modules/AdminAccounts'
import React from 'react'

type Props = {}

const AdminAccount = (props: Props) => {
    return (
        <AdminAccountContainer />
    )
}

export default AdminAccount