import { firestore } from "libs/firebase/@firebase";
import { COLLECTIONS } from "constant";
import { Admin, Location } from "models/schema";
import { getDocumentById, subscribeToCollection } from "utils/firebaseUtils";
import {
  doc,
  onSnapshot,
  DocumentData,
  addDoc,
  setDoc,
  deleteDoc,
  collection,
  FirestoreError,
  where,
  updateDoc,
  Timestamp,
  getFirestore,
} from "firebase/firestore";
import { getDocs, query, orderBy } from "firebase/firestore";
interface GetUserValues {
  uid: string;
}

class Profile {
  getCurrentUser = async (
    values: GetUserValues = { uid: "" }
  ): Promise<Admin | DocumentData> => {
    const { uid } = values;

    return new Promise<Admin | DocumentData>((resolve, reject) => {
      try {
        onSnapshot(doc(firestore, COLLECTIONS.USERS, uid), (snap) => {
          if (snap.exists()) {
            resolve(snap.data() as Admin);
          } else {
            reject(new Error("User not found!"));
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  // getCurrentUser = ({
  //    dataCallback,
  //    errorCallback,
  // }: {
  //    dataCallback: (data: Admin[]) => void;
  //    errorCallback: (error: FirestoreError) => void;
  // }): Promise<{ data: Admin[]; unsubscribe: () => void }> => {
  //    return subscribeToCollection<Admin>(
  //       firestore,
  //       COLLECTIONS.ADMINS,
  //       dataCallback,
  //       errorCallback
  //    );
  // };

  getUserById2 = async (params: {
    uid: string;
    dataCallback: (data: Admin) => void;
    errorCallback: (error: FirestoreError) => void;
  }): Promise<{ data: Admin; unsubscribe: () => void }> => {
    const { uid, dataCallback, errorCallback } = params;

    const res = getDocumentById<Admin>(
      firestore,
      COLLECTIONS.USERS,
      uid,
      dataCallback,
      errorCallback
    );

    console.log("res", uid);

    return res;
  };
  getUserById = async (params: {
    uid: string;
    dataCallback: (data: Admin | null) => void;
    errorCallback: (error: FirestoreError) => void;
  }): Promise<{ data: Admin | null; unsubscribe: () => void }> => {
    const { uid, dataCallback, errorCallback } = params;

    try {
      const db = getFirestore(); // Initialize Firestore
      const usersCollection = collection(db, COLLECTIONS.USERS); // Reference the users collection

      // Create a query with the where clause for users collection
      const userQuery = query(usersCollection, where("uid", "==", uid));

      // Subscribe to real-time updates using onSnapshot for user data
      const unsubscribe = onSnapshot(
        userQuery,
        async (querySnapshot) => {
          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0]; // Assuming UID is unique and you'll only get one result
            const userData = userDoc.data() as Admin;
            let id = userDoc.id;

            // Fetch associated location IDs from userLocations collection
            const userLocationsCollection = collection(
              db,
              COLLECTIONS.USERLOCATIONS
            );
            const locationQuery = query(
              userLocationsCollection,
              where("userId", "==", id)
            );

            const locationDocs = await getDocs(locationQuery);
            const locationIds = locationDocs.docs.map(
              (doc) => doc.data().locationId as string
            );

            // Attach location IDs to user data
            userData.locationIds = locationIds;

            // Pass the updated user data with locations to the callback
            dataCallback(userData);
          } else {
            console.log("No user found with this UID");
            dataCallback(null); // Pass null if no user is found
          }
        },
        (error) => {
          console.error("Error fetching user data from Firestore:", error);
          errorCallback(error); // Pass the error to the callback
        }
      );

      return { data: null, unsubscribe }; // Return null initially and handle updates via onSnapshot
    } catch (error) {
      console.error("Error querying Firestore:", error);
      throw error;
    }
  };

  getAllAdmins = (
    dataCallback: (admins: Admin[]) => void,
    errorCallback: (error: FirestoreError) => void
  ): (() => void) => {
    // Return the unsubscribe function
    return subscribeToCollection<Admin>(
      firestore,
      COLLECTIONS.USERS, // Assuming the collection name is 'users'
      dataCallback,
      errorCallback
    );
  };

  getAllAdminsWithLocations = (
    dataCallback: (admins: Admin[]) => void,
    errorCallback: (error: FirestoreError) => void
  ): (() => void) => {
    const adminCollectionRef = collection(firestore, COLLECTIONS.USERS);

    // Set up Firestore real-time subscription using onSnapshot with ordering by createdAt desc
    const unsubscribe = onSnapshot(
      query(adminCollectionRef, orderBy("createdAt", "desc")),
      async (snapshot) => {
        const admins: Admin[] = [];

        // Loop over each admin document and fetch related locations
        for (const adminDoc of snapshot.docs) {
          const adminData = adminDoc.data() as Admin;
          adminData.id = adminDoc.id;

          // Fetch associated locations from userLocations collection
          // const userLocationQuery = query(
          //   collection(firestore, COLLECTIONS.USERLOCATIONS),
          //   where("userId", "==", adminDoc.id)
          // );
          // const userLocationSnapshot = await getDocs(userLocationQuery);

          // const locationIds = userLocationSnapshot.docs.map(
          //   (doc) => doc.data().locationId
          // );

          // // Fetch location details for each locationId
          // const locations: Location[] = [];
          // for (const locationId of locationIds) {
          //   const locationDoc = await getDoc(
          //     doc(firestore, COLLECTIONS.LOCATIONS, locationId)
          //   );
          //   if (locationDoc.exists()) {
          //     locations.push({
          //       id: locationId,
          //       ...locationDoc.data(),
          //     } as Location);
          //   }
          // }

          // adminData.locations = locations;
          admins.push(adminData);
        }

        dataCallback(admins); // Invoke the callback with the fetched data
      },
      (error) => {
        errorCallback(error); // Handle errors
      }
    );

    // Return the unsubscribe function to stop listening to changes when needed
    return unsubscribe;
  };

  createAdmin = async (data: any): Promise<void> => {
    try {
      const adminCollectionRef = collection(firestore, COLLECTIONS.USERS); // Assuming the collection is named 'users'
      data.name = data.name.toLowerCase();
      data.email = data.email.toLowerCase();
      // Extract locations from data and remove it from the data object
      const { locations, ...adminData } = data;
      adminData.createdAt = new Date();
      // Create the admin without the locations array
      const adminRef = await addDoc(adminCollectionRef, adminData);
      const adminId = adminRef.id;

      // Associate locations with the created admin
      if (locations && locations.length > 0) {
        await this.associateUserWithLocations(adminId, locations);
      }

      console.log("Admin created successfully");
    } catch (error) {
      console.error("Error creating admin:", error);
      throw error;
    }
  };

  // Method to update an existing admin
  updateAdmin = async (adminId: string, data: any): Promise<void> => {
    try {
      const adminDocRef = doc(firestore, COLLECTIONS.USERS, adminId);
      data.name = data.name.toLowerCase();
      data.email = data.email.toLowerCase();
      // Extract locations from data and remove it from the data object
      const { locations, ...adminData } = data;

      // Update the admin without the locations array
      await setDoc(adminDocRef, adminData, { merge: true });

      // Associate locations with the updated admin
      if (locations && locations.length > 0) {
        await this.associateUserWithLocations(adminId, locations);
      }

      console.log("Admin updated successfully");
    } catch (error) {
      console.error("Error updating admin:", error);
      throw error;
    }
  };

  associateUserWithLocations = async (
    userId: string,
    locationIds: string[]
  ): Promise<void> => {
    const userLocationCollectionRef = collection(
      firestore,
      COLLECTIONS.USERLOCATIONS
    );

    try {
      // Fetch current associations from userLocations for the given userId
      const existingAssociationsQuery = query(
        userLocationCollectionRef,
        where("userId", "==", userId)
      );
      const existingAssociationsSnapshot = await getDocs(
        existingAssociationsQuery
      );

      const existingLocationIds: string[] =
        existingAssociationsSnapshot.docs.map((doc) => doc.data().locationId);

      // Determine which locations need to be added
      const locationsToAdd = locationIds.filter(
        (locationId) => !existingLocationIds.includes(locationId)
      );

      // Determine which locations need to be removed
      const locationsToRemove = existingLocationIds.filter(
        (locationId) => !locationIds.includes(locationId)
      );

      // Add new associations
      for (const locationId of locationsToAdd) {
        await addDoc(userLocationCollectionRef, {
          userId,
          locationId,
        });
      }
      // Remove old associations
      for (const locationId of locationsToRemove) {
        const userLocationToDelete = existingAssociationsSnapshot.docs.find(
          (doc) => doc.data().locationId === locationId
        );

        if (userLocationToDelete) {
          await deleteDoc(
            doc(firestore, COLLECTIONS.USERLOCATIONS, userLocationToDelete.id)
          );
        }
      }
      console.log("User-location associations updated successfully");
    } catch (error) {
      console.error("Error updating user-location associations:", error);
      throw new Error("Failed to update user-location associations");
    }
  };

  deleteAdmin = async (adminId: string): Promise<void> => {
    try {
      const adminDocRef = doc(firestore, COLLECTIONS.USERS, adminId); // Assuming 'users' collection
      await deleteDoc(adminDocRef);
      console.log(`Admin with ID ${adminId} has been deleted.`);
    } catch (error) {
      console.error("Error deleting admin:", error);
      throw error;
    }
  };

  getUserByEmail = async (email: string) => {
    try {
      const usersRef = collection(firestore, COLLECTIONS.USERS); // Assuming 'users' is your collection name
      const q = query(usersRef, where("email", "==", email)); // Query for users with matching email
      const querySnapshot = await getDocs(q);

      const users = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return users; // Return an array of users (empty array if no match found)
    } catch (error) {
      console.error("Error fetching users by email:", error);
      throw error;
    }
  };

  searchUsersByNameOrEmail = async (searchTerm: string): Promise<any[]> => {
    try {
      const usersRef = collection(firestore, COLLECTIONS.USERS);
      // convert search term to lowercase
      searchTerm = searchTerm.toLowerCase();

      // Create "starts with" queries for both name and email fields
      const queryByName = query(
        usersRef,
        where("name", ">=", searchTerm),
        where("name", "<=", searchTerm + "\uf8ff")
      );

      const queryByEmail = query(
        usersRef,
        where("email", ">=", searchTerm),
        where("email", "<=", searchTerm + "\uf8ff")
      );

      // Run both queries in parallel
      const [nameSnapshot, emailSnapshot] = await Promise.all([
        getDocs(queryByName),
        getDocs(queryByEmail),
      ]);

      // Map over both snapshots to gather matching users
      const usersByName = nameSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const usersByEmail = emailSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Combine both results, using Set to remove duplicates
      const users = [...usersByName, ...usersByEmail].filter(
        (user, index, self) =>
          index ===
          self.findIndex(
            (u) => u.id === user.id // Ensures no duplicate users by comparing IDs
          )
      );

      return users; // Return an array of unique users matching the search term
    } catch (error) {
      console.error("Error searching users by name or email:", error);
      throw error;
    }
  };
  //write function to get user by email

  getAdminByEmail = async (email: string): Promise<Admin[]> => {
    return new Promise(async (resolve, reject) => {
      try {
        const usersCollectionRef = collection(firestore, COLLECTIONS.USERS);

        const usersQuery = query(
          usersCollectionRef,
          where("email", "==", email)
        );

        const querySnapshot = await getDocs(usersQuery);

        const users = await Promise.all(
          querySnapshot.docs.map(async (docSnapshot) => {
            const adminData = {
              id: docSnapshot.id,
              ...docSnapshot.data(),
            } as Admin;

            // Update lastLogin field to current timestamp
            const adminDocRef = doc(firestore, COLLECTIONS.USERS, adminData.id);
            await updateDoc(adminDocRef, {
              lastLogin: Timestamp.now(),
            });

            return adminData;
          })
        );

        resolve(users);
      } catch (error) {
        reject(error);
      }
    });
  };

  getAllAdmins2 = (): Promise<Admin[]> => {
    return new Promise(async (resolve, reject) => {
      try {
        const usersCollectionRef = collection(firestore, COLLECTIONS.USERS);

        const adminsQuery = query(
          usersCollectionRef,
          orderBy("createdAt", "desc")
        );

        const querySnapshot = await getDocs(adminsQuery);

        const admins = querySnapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as Admin)
        );
        resolve(admins);
      } catch (error) {
        reject(error);
      }
    });
  };
}

const ProfileService = new Profile();

export { ProfileService };
