import { auth } from 'libs/firebase/@firebase';
import { ROUTES } from 'constant';
import { useAuthContext } from 'context/AuthContext';
import React, { ComponentType } from 'react';
import { Navigate } from 'react-router-dom';

interface WithProtectionProps {
   type: string;
}

const withProtection = <P extends object>(
   Component: ComponentType<P>,
   { type }: WithProtectionProps
) => {
   return (props: P) => {
      const { user } = useAuthContext();

      if (type === 'login') {
         if (auth.currentUser) {
            return <Navigate to={'/'} />;
         }
      }

      return <Component {...props} />;
   };
};

export default withProtection;
