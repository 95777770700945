
import React from "react";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import loginbgImage from "../../../../blisstrax_assets/loginBg.png";

type Props = {
  children: React.ReactNode;
};

export const AuthLayout = (props: Props) => {
  const { children } = props;
  return (
    <React.Fragment>
      <Box
        width="100%"
        display="flex"
        minHeight="100vh"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        position="relative"
        sx={{
          background: `linear-gradient(180deg, #EAEAEA 72.91%, #0A9959 144.38%)`,

          // backgroundSize: 'cover',
          // backgroundPosition: 'top center',
          // backgroundRepeat: 'no-repeat',
        }}
      >
        <Box
          component="img"
          src="assetsOaks/icon/oaksLogo.png"
          alt="logo"
          sx={{
            position: 'absolute',
            top: 50,
            width: '354px',
            height: 'auto',
          }}
        />
        <Container
          sx={{
            width: { md: "30%", sm: "60%", xs: "90%" },
            // mt: { xs: 10, sm: 20 },
            // padding: 4,

          }}
        >
          {children}
        </Container>
      </Box>
    </React.Fragment>
  );
};
