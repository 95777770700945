import React, { useState } from "react";
import theme from "styles/theme";
import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItemButton from "@mui/material/ListItemButton";
import { useAuthContext } from "context/AuthContext";
import { useModal } from "hooks";
// import { SettingMenu } from "components";
import { COLORS, ROUTES } from "constant";
import { useLocation, useNavigate } from "react-router";
import { Avatar, Box, ListItemIcon } from "@mui/material";
import { AuthService } from "services/Auth.Services";
import CommonPopup from "components/CommonPopup/CommonPopup";

export const Sidebar = (props: any) => {
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { user } = useAuthContext();

  type ListItemProps = {
    text: string;
    path: string;
    active: string[];
    icon?: string;
  };

  const generateListItem = ({ text, path, active, icon }: ListItemProps) => {
    return (
      <ListItem key={path}>
        <ListItemButton
          onClick={() => navigate(path)}
          selected={active?.includes(pathname)}
        >
          {/* <img src={icon} alt="" /> */}
          <ListItemText
            primary={text}
            primaryTypographyProps={{
              fontWeight: 700,
              fontSize: "16px",
              color: COLORS.dark.light,
            }}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Drawer
      variant={isMdUp ? "permanent" : "temporary"}
      {...props}
      sx={{
        "& .MuiDrawer-paper": {
          backgroundColor: "#DCDCDC",
        },
      }}
    >
      <List
        className="MuiList-sideBar-menu"
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ListItem
          sx={{
            my: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            src="assetsOaks/icon/oaksLogo.png"
            alt="logo"
            sx={{
              width: "152px",
              height: "78px",
              objectFit: "contain",
            }}
          ></Box>
        </ListItem>
        

        {user?.role !== "Associate" &&
          generateListItem({
            text: "Location",
            path: ROUTES.LOCATION,
            active: [ROUTES.LOCATION],
          })}


        {generateListItem({
          text: "Entries",
          path: ROUTES.ENTRIES,
          active: [ROUTES.ENTRIES],
        })}
        {user?.role === "Admin" &&
          generateListItem({
            text: "Users",
            path: ROUTES.ADMIN_ACCOUNTS,
            active: [ROUTES.ADMIN_ACCOUNTS],
          })}
        {generateListItem({
          text: "Revenue Report",
          path: ROUTES.REVENUE_REPORT,
          active: [ROUTES.REVENUE_REPORT],
        })}

        {/* 
            {generateListItem({
               text: 'Dashboard',
               path: ROUTES.DASHBOARD,
               active: [ROUTES.DASHBOARD],
            })}
            {generateListItem({
               text: 'Library',
               path: ROUTES.LIBRARY,
               active: [ROUTES.LIBRARY],
            })}
            {generateListItem({
               text: 'Manage User',
               path: ROUTES.MANAGE_USER,
               active: [ROUTES.MANAGE_USER],
            })}
            {generateListItem({
               text: 'Bliss Tips',
               path: ROUTES.BLISS_TIPS,
               active: [ROUTES.BLISS_TIPS],
            })}
            {generateListItem({
               text: 'Legal',
               path: ROUTES.LEGAL,
               active: [ROUTES.LEGAL],
            })} */}

        <div style={{ flex: 1 }} />
        <Box position={"absolute"} zIndex={999} bottom={100} right={5}></Box>
        <ListItem
          sx={{ position: "relative" }}
          onClick={() => setIsPopupOpen(true)}
        >
          <ListItemButton>
            <ListItemText
              sx={{ ml: 2 }}
              primary={
                <Box
                  gap={2}
                  component="span"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Box
                    component="span"
                    sx={{
                      fontWeight: 800,
                      fontSize: "23px",
                      color: COLORS.black.main,
                    }}
                  >
                    Log Out
                  </Box>
                </Box>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
      <CommonPopup
        open={isPopupOpen}
        title={"Are you sure?"}
        message={`Do you want to logout?`}
        confirmText={"Yes"}
        cancelText={"No, cancel"}
        onConfirm={() => AuthService.logOut()}
        onCancel={() => setIsPopupOpen(false)}
      />
    </Drawer>
  );
};

export default Sidebar;
