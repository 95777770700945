import React from "react";
import EnterCodeContainer from "modules/Authentication/containers/EnterCodeContainer/EnterCodeContainer";

type Props = {};

const EnterCode = (props: Props) => {
  return <EnterCodeContainer />;
};

export default EnterCode;
