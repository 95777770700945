import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FormDataTypeEntry } from "types";
import { useDataContext } from "context/DataContext";
import { GroupedLocation, Location } from "models/schema";
import CommonPopup from "components/CommonPopup/CommonPopup";
import { Timestamp } from "firebase/firestore";
import { EntryService } from "services/Entry.Services";
import { useNotification } from "../../../../context/notificationContext";
// Utility function to calculate the previous Monday
const getPreviousMonday = (): Dayjs => {
  const today = dayjs();
  const dayOfWeek = today.day(); // Get the current day of the week (0 for Sunday, 1 for Monday, etc.)
  if (dayOfWeek === 1) {
    // If today is Monday
    return today;
  }
  return today.subtract(dayOfWeek - 1, "day"); // Subtract to get the previous Monday
};

// Utility function to calculate the days of the week based on a start date
const getWeekDays = (startDate: Dayjs): { day: string; date: string }[] => {
  const daysOfWeek = [];
  for (let i = 0; i < 7; i++) {
    const day = startDate.add(i, "day");
    daysOfWeek.push({
      day: day.format("dddd"), // Get the name of the day (e.g., "Monday")
      date: day.format("MM/DD/YYYY"), // Get the formatted date
    });
  }
  return daysOfWeek;
};

// Validation schema using Yup
const validationSchema = Yup.object({
  location: Yup.object().required("Location is required"),
  stand: Yup.string().required("Stand is required"),
});

interface CreateEntryModalProps {
  locations: Location[];
  open: boolean;
  handleClose: () => void;
  onConfirm: (data: FormDataTypeEntry) => void;
}

const CreateEntryModal: React.FC<CreateEntryModalProps> = ({
  locations,
  open,
  handleClose,
  onConfirm,
}) => {
  const previousMonday = getPreviousMonday();

  const [formData, setFormData] = useState<FormDataTypeEntry>({
    entryId: "",
    location: null,
    locationName: "",
    stand: "",
    selectedDate: previousMonday,
    startDate: previousMonday.toISOString(),
    endDate: previousMonday.add(6, "day").toISOString(),
    status: "open",
    dateRange: {
      start: previousMonday.format("MMM D"),
      end: previousMonday.add(6, "day").format("MMM D"),
    },
    weekDays: getWeekDays(previousMonday),
  });

  const { selectedEntryData, setSelectedEntryData } = useDataContext();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setAlert } = useNotification();
  const [standNumbers, setStandNumbers] = useState<
    { standNumber: string; standName: string; id: string; location: Location }[]
  >([]);

  // Update stand dropdown based on selected location
  const handleLocationChange = (selectedLocationName: string) => {
    const selectedLocation = locations.find(
      (loc) => loc?.locationName === selectedLocationName
    );
    // console.log("selectedLocation", selectedLocation);
    // return;
    if (selectedLocation) {
      setFormData((prev) => ({
        ...prev,
        locationName: selectedLocation?.locationName,
        stand: "", // Reset stand when location changes
      }));
      console.log("selectedLocation.stands", selectedLocation?.stands);
      setStandNumbers(selectedLocation?.stands || []); // Populate standNumbers from the location's stands array
    }
  };

  // Update stand in formData when a stand is selected
  const handleStandChange = (selectedStandNumber: string) => {
    // console.log("selectedStandNumber", selectedStandNumber);
    const selectedStand = standNumbers.find(
      (stand) => stand.standNumber === selectedStandNumber
    );
    console.log("selectedStandLocation", selectedStand?.location);
    setFormData((prev) => ({
      ...prev,
      stand: selectedStandNumber,
      location: selectedStand?.location || null,
    }));
  };

  const handleFormChange = (key: keyof FormDataTypeEntry, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // Handle Date Increase (Next Week)
  const handleIncreaseDate = () => {
    const newStartDate = dayjs(formData.startDate).add(7, "day");
    setFormData((prev) => ({
      ...prev,
      startDate: newStartDate.toISOString(),
      endDate: newStartDate.add(6, "day").toISOString(),
      dateRange: {
        start: newStartDate.format("MMM D"),
        end: newStartDate.add(6, "day").format("MMM D"),
      },
      weekDays: getWeekDays(newStartDate),
    }));
  };

  // Handle Date Decrease (Previous Week)
  const handleDecreaseDate = () => {
    const newStartDate = dayjs(formData.startDate).subtract(7, "day");
    setFormData((prev) => ({
      ...prev,
      startDate: newStartDate.toISOString(),
      endDate: newStartDate.add(6, "day").toISOString(),
      dateRange: {
        start: newStartDate.format("MMM D"),
        end: newStartDate.add(6, "day").format("MMM D"),
      },
      weekDays: getWeekDays(newStartDate),
    }));
  };

  // Handle Confirmation
  const handleConfirm = async () => {
    setLoading(true);
    try {
      const existingEntry = await EntryService.checkEntryExist(
        Timestamp.fromDate(new Date(formData?.startDate || "")),
        Timestamp.fromDate(new Date(formData?.endDate || "")),
        formData?.location?.id || ""
      );
      console.log("existingEntry", existingEntry?.rates);

      if (existingEntry) {
        if (existingEntry.status === "closed") {
          setAlert({
            show: true,
            message: `A similar entry already exists but it is closed. Please create a new entry or mark that as open before editing.`,
            variant: "error",
          });
          setLoading(false);
          return;
        }
        // Update the location data
        const updatedLocation: Location = {
          ...formData.location, // Preserve existing location data
          id: formData.location?.id || "", // Ensure `id` is a string
          locationRc: formData.location?.locationRc || "", // Ensure `locationRc` is a string
          locationName: formData.location?.locationName || "", // Ensure `locationName` is a string
          standNumber: formData.location?.standNumber || "", // Ensure `standNumber` is a string
          standName: formData.location?.standName || "", // Ensure `standName` is a string
          blueBar: existingEntry?.blueBar || formData.location?.blueBar || "", // Fallback to an empty string
          conditionalVip:
            existingEntry?.conditionalVip ||
            formData.location?.conditionalVip ||
            "",
          rates: existingEntry?.rates || [],
          allRates: existingEntry?.allRates || [],
          conditionalRates: existingEntry?.conditionalRates || [],
          validations: existingEntry?.validations || [],
          users: formData.location?.users || [],
          stands: formData.location?.stands || [],
          createdAt: formData.location?.createdAt || Timestamp.now(), // Default to current timestamp if undefined
          updatedAt: formData.location?.updatedAt || Timestamp.now(), // Default to current timestamp if undefined
        };

        setFormData((prev) => ({
          ...prev,
          location: updatedLocation,
          entryId: existingEntry.id, // Assign entry ID from existing entry
        }));

        // formData.entryId = existingEntry.id;
        setLoading(false);
        setIsPopupOpen(true);
      } else {
        formData.entryId = "";
        setSelectedEntryData(formData);
        onConfirm(formData);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleRedirect = () => {
    setIsPopupOpen(false);
    handleClose();
    setSelectedEntryData(formData);
    onConfirm(formData);
  };

  return (
    <Formik
      initialValues={formData}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleConfirm}
    >
      {({ errors, touched, handleSubmit }) => (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="create-entry-modal"
        >
          <Box sx={modalStyle}>
            <Box
              display={"flex"}
              justifyContent="space-between"
              flexDirection={{ xs: "column", md: "row" }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", marginBottom: 2 }}
              >
                Create Entry
              </Typography>
              <Box display={"flex"} gap={{ xs: 1, md: 3 }} alignItems="center">
                <IconButton onClick={handleDecreaseDate}>
                  <img src="/assetsOaks/icon/leftArrow.svg" alt="left arrow" />
                </IconButton>
                <Typography fontSize={"23px"} fontWeight={700}>
                  {formData.dateRange.start} - {formData.dateRange.end}
                </Typography>
                <IconButton onClick={handleIncreaseDate}>
                  <img
                    src="/assetsOaks/icon/rightArrow.svg"
                    alt="right arrow"
                  />
                </IconButton>
              </Box>
            </Box>

            <Form onSubmit={handleSubmit}>
              {/* Location Select */}
              <Box sx={formStyle}>
                <Box sx={selectStyle}>
                  <Typography variant="body1">Location</Typography>
                  <Select
                    value={formData.locationName} // Convert the object to a string for comparison
                    onChange={(e) =>
                      handleLocationChange(e.target.value as string)
                    } // Parse it back
                    displayEmpty
                    fullWidth
                    error={touched.location && Boolean(errors.location)} // Set the error state
                  >
                    <MenuItem value="">
                      <span>Select Location</span>
                    </MenuItem>
                    {locations.map((location) => (
                      <MenuItem
                        key={location.locationRc}
                        value={location.locationName}
                      >
                        {" "}
                        {/* Stringify the whole object */}
                        {location.locationRc} - {location.locationName}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.location && errors.location && (
                    <Typography color="error" variant="caption">
                      {errors.location}
                    </Typography>
                  )}
                </Box>

                {/* Stand Select */}
                <Box sx={selectStyle}>
                  <Typography variant="body1">Stand#</Typography>
                  <Select
                    value={formData.stand || ""}
                    onChange={(e) =>
                      handleStandChange(e.target.value as string)
                    }
                    displayEmpty
                    fullWidth
                    error={touched.stand && Boolean(errors.stand)} // Set the error state
                  >
                    <MenuItem value="">
                      <span>Select Stand</span>
                    </MenuItem>
                    {standNumbers.map((stand) => (
                      <MenuItem key={stand.id} value={stand.standNumber}>
                        {stand.standNumber}-{stand.standName}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.stand && errors.stand && (
                    <Typography color="error" variant="caption">
                      {errors.stand}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Button
                type="submit"
                variant="contained"
                sx={{
                  display: "flex",
                  height: "40px",
                  alignItems: "center",
                  justifyContent: "center",
                  mx: "auto",
                  backgroundColor: "#0A9959",
                  color: "#fff",
                  marginTop: "20px",
                }}
              >
                <Box
                  component="img"
                  src="/assetsOaks/icon/plusbtn.svg"
                  alt="export"
                  sx={{
                    width: "19px",
                    height: "19px",
                    objectFit: "contain",
                    marginRight: "7px",
                  }}
                />
                Create Entry {loading && "..."}
              </Button>
            </Form>
            <CommonPopup
              open={isPopupOpen}
              title={"A similar entry already exists!"}
              message={`Would you like to be redirected to that entry?`}
              confirmText={"Yes, please redirect me."}
              cancelText={"No, cancel"}
              onConfirm={handleRedirect}
              onCancel={() => setIsPopupOpen(false)}
            />
          </Box>
        </Modal>
      )}
    </Formik>
  );
};

// Styles for Modal and Form
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", md: 550 },
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const formStyle = {
  marginTop: 4,
  display: "flex",
  justifyContent: "space-between",
  gap: 4,
};

const selectStyle = {
  width: "48%",
};

export default CreateEntryModal;
