import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Grid,
  Box,
} from "@mui/material";
import { CBox } from "components";
import CreateUser from "modules/ManageUser/components/CreateUser";
import CommonPopup from "components/CommonPopup/CommonPopup";
import { ProfileService } from "services/Profile.Services"; // Import ProfileService
import { deleteUserFn } from "../../../../utils/firebaseFunctions";
import { Timestamp } from "firebase/firestore";
import { format } from "date-fns";
type CardData = {
  id: string;
  uid: string;
  role: string;
  status: string;
  name: string;
  email: string;
  phone: string;
  lastLogin: Timestamp;
};

type AdminCardProps = {
  data: CardData;
  onEdit: (user: CardData) => void; // New prop to handle edit
};

const AdminCard: React.FC<AdminCardProps> = ({ data, onEdit }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupClose, setIsPopupClose] = useState(false);
  const [loading, setLoading] = useState(false);

  // Handle delete confirmation
  const handleDelete = () => {
    setIsPopupOpen(true);
  };

  // Handle delete action and call the ProfileService to delete the admin
  const handleConfirmDelete = async () => {
    try {
      setLoading(true); // Show a loading state if needed
      const response = await deleteUserFn({ uid: data.uid }); // Call deleteUserFn to delete user
      console.log("Admin deleted successfully.", response);
      await ProfileService.deleteAdmin(data.id); // Call ProfileService to delete admin
      console.log("Admin deleted successfully.");
      setIsPopupOpen(false);
      setIsPopupClose(true); // Show success confirmation popup
    } catch (error) {
      console.error("Error deleting admin:", error);
      setLoading(false); // Remove loading state
    }
  };

  // Close the success confirmation popup
  const handleConfirmPopupClose = () => {
    setIsPopupClose(false);
  };
  const formatDate = (date: any) => {
    if (!date) return ""; // Handle cases where date is undefined or null

    // Check if the date is a Firestore Timestamp
    if (date instanceof Timestamp) {
      const jsDate = date.toDate(); // Convert Firestore Timestamp to JavaScript Date
      return format(jsDate, "MM/dd/yyyy"); // Format the JavaScript Date to MM/DD/YYYY
    }

    return ""; // Return 'Invalid Date' if the date is not a Timestamp
  };
  const formatTime = (date: any) => {
    if (!date) return ""; // Handle cases where date is undefined or null

    // Check if the date is a Firestore Timestamp
    if (date instanceof Timestamp) {
      const jsDate = date.toDate(); // Convert Firestore Timestamp to JavaScript Date
      return format(jsDate, "hh:mm a"); // Format the JavaScript Date to MM/DD/YYYY
    }

    return ""; // Return 'Invalid Date' if the date is not a Timestamp
  };

  return (
    <Card sx={{ backgroundColor: "#EAEAEA", padding: "3px", borderRadius: 2 }}>
      <CardContent>
        <CBox jsb ac wrp>
          <Typography variant="h5_bold">{data.role}</Typography>
          <Typography variant="h5_bold" px={2}>
            {data.status}
          </Typography>
        </CBox>
        <CBox jsb ac wrp mt={1}>
          <Typography variant="h5" color={"black"}>
            {data.name}
          </Typography>

          <CBox gap={0}>
            <IconButton onClick={handleDelete}>
              <img src="/assetsOaks/icon/delete.svg" />
            </IconButton>

            <IconButton onClick={() => onEdit(data)}>
              <img src="/assetsOaks/icon/edit.svg" />
            </IconButton>
          </CBox>
        </CBox>

        <CBox wrp jsb as>
          <CBox col mt={0} gap={0} color={"black"}>
            <Typography variant="h5">{data.email}</Typography>
            <Typography variant="h5">{data.phone}</Typography>
          </CBox>
          <CBox jsb ac wrp color={"black"}>
            <Typography variant="h5">{formatDate(data?.lastLogin)}</Typography>
            <Typography variant="h5">{formatTime(data?.lastLogin)}</Typography>
          </CBox>
        </CBox>
      </CardContent>

      {/* Delete confirmation popup */}
      <CommonPopup
        open={isPopupOpen}
        title={"Are you sure?"}
        message={`Do you want to delete ${data.name}?`}
        confirmText={loading ? "Deleting..." : "Yes, delete user"}
        cancelText={"No, cancel"}
        onConfirm={handleConfirmDelete}
        onCancel={() => setIsPopupOpen(false)}
      />

      {/* Success confirmation popup */}
      <CommonPopup
        open={isPopupClose}
        title={""}
        message={`User ${data.name} has been deleted.`}
        confirmText={"Close"}
        onConfirm={handleConfirmPopupClose}
        onCancel={() => setIsPopupOpen(false)}
      />
    </Card>
  );
};

export default AdminCard;
