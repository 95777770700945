import React, { useEffect, useRef, useState } from "react";
import CBox from "components/CBox";
import { Box, Typography, IconButton } from "@mui/material";
import { useAuthContext } from "context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import dayjs, { Dayjs } from "dayjs";
import { FormDataTypeEntry } from "types";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { auth } from "libs/firebase/@firebase";
import { useDataContext } from "context/DataContext";

interface HeaderProps {
  title?: string;
}

// Utility function to calculate the previous Monday
const getPreviousMonday = (): Dayjs => {
  const today = dayjs();
  const dayOfWeek = today.day();
  if (dayOfWeek === 1) {
    return today;
  }
  return today.subtract(dayOfWeek - 1, "day");
};

const Header = ({ title }: HeaderProps) => {
  const { user } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();
  const calendarRef = useRef<HTMLDivElement>(null);
  const { selectedEntryData, setDates } = useDataContext();

  const previousMonday = getPreviousMonday();

  // Initialize selectedDate without forcing the week to reset when the user navigates months
  const [selectedDate, setSelectedDate] = useState({
    startDate: previousMonday.toDate(),
    endDate: previousMonday.add(6, "day").toDate(),
    key: "selection",
  });

  const [dateRange, setDateRange] = useState({
    start: previousMonday.format("MMM D"),
    end: previousMonday.add(6, "day").format("MMM D"),
  });

  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  // Load dates into context on component mount
  useEffect(() => {
    setDates({
      startDate: selectedDate.startDate,
      endDate: selectedDate.endDate,
    });
  }, []);

  // Handle date change from DatePicker without resetting the week
  const handleDateChange = (ranges: any) => {
    const { startDate, endDate } = ranges.selection;
    setSelectedDate(ranges.selection);
    setDateRange({
      start: dayjs(startDate).format("MMM D"),
      end: dayjs(endDate).format("MMM D"),
    });
    setDates({
      startDate: startDate,
      endDate: endDate,
    });
  };

  // Close calendar if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node)
      ) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleIncreaseDate = () => {
    const newStartDate = dayjs(selectedDate.startDate).add(7, "day").toDate();
    const newEndDate = dayjs(newStartDate).add(6, "day").toDate();

    setDateRange({
      start: dayjs(newStartDate).format("MMM D"),
      end: dayjs(newEndDate).format("MMM D"),
    });
    setSelectedDate({
      startDate: newStartDate,
      endDate: newEndDate,
      key: "selection",
    });
    setDates({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  const handleDecreaseDate = () => {
    const newStartDate = dayjs(selectedDate.startDate)
      .subtract(7, "day")
      .toDate();
    const newEndDate = dayjs(newStartDate).add(6, "day").toDate();

    setDateRange({
      start: dayjs(newStartDate).format("MMM D"),
      end: dayjs(newEndDate).format("MMM D"),
    });
    setSelectedDate({
      startDate: newStartDate,
      endDate: newEndDate,
      key: "selection",
    });
    setDates({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  const togglePickerDate = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleCloseDatePicker = () => {
    setShowDatePicker(false);
  };

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <CBox jsb display={"flex"} flexDirection={"column"} position={"relative"}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        px={{ xs: "15px", md: 4 }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Typography
          fontSize={"30px"}
          fontWeight={800}
          display={{ xs: "none", md: "flex" }}
          alignItems={"center"}
          gap={"2px"}
        >
          {location.pathname === "/create-entry" && (
            <img
              src="/assetsOaks/icon/leftArrow.svg"
              alt="left arrow"
              onClick={handleClickBack}
              style={{
                width: "30px",
                height: "30px",
                objectFit: "contain",
                cursor: "pointer",
              }}
            />
          )}
          {title}
        </Typography>
        <Box
          display={{ xs: "flex", md: "none" }}
          justifyContent={"space-between"}
          pt={2}
        >
          <Typography
            fontSize={{ xs: "18px", md: "30px" }}
            fontWeight={{ xs: 700, md: 800 }}
            display={{ xs: "flex", md: "none" }}
            alignItems={"center"}
            gap={"2px"}
          >
            {location.pathname === "/create-entry" && (
              <img
                src="/assetsOaks/icon/leftArrow.svg"
                alt="left arrow"
                onClick={handleClickBack}
                style={{
                  width: "30px",
                  height: "30px",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
              />
            )}
            {title}
          </Typography>
          <Box
            display={{ xs: "flex", md: "none" }}
            alignItems={"center"}
            gap={"3px"}
          >
            <Typography fontSize={"25px"} fontWeight={600}>
              Welcome,
              {user?.name
                ? user.name
                    .split(" ")
                    .map(
                      (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                    .join(" ")
                : ""}
            </Typography>
            {/* <KeyboardArrowDownIcon sx={{ width: "30px", height: "30px" }} /> */}
          </Box>
        </Box>
        {(location.pathname === "/revenue-report" ||
          location.pathname === "/entries") && (
          <Box display={"flex"} gap={{ xs: 1, md: 3 }} alignItems="center">
            <IconButton onClick={handleDecreaseDate}>
              <img src="/assetsOaks/icon/leftArrow.svg" alt="left arrow" />
            </IconButton>
            <Typography
              fontSize={{ xs: "24px", md: "30px" }}
              fontWeight={{ xs: 400, md: 800 }}
            >
              {dateRange.start} - {dateRange.end}
            </Typography>
            <IconButton onClick={togglePickerDate}>
              <img src="/assetsOaks/icon/calandar.svg" alt="calendar" />
            </IconButton>
            <IconButton onClick={handleIncreaseDate}>
              <img src="/assetsOaks/icon/rightArrow.svg" alt="right arrow" />
            </IconButton>
          </Box>
        )}

        {location.pathname === "/create-entry" && (
          <Box
            display={"flex"}
            gap={{ xs: 1, md: 3 }}
            alignItems={{ xs: "start", md: "center" }}
            flexDirection={{ xs: "column", md: "row" }}
          >
            <Box display={"flex"} gap={{ xs: "23px", md: 3 }}>
              <Typography fontSize={"20px"} fontWeight={700}>
                Location RC: {selectedEntryData?.location?.locationRc || "N/A"}
              </Typography>
              <Typography fontSize={"20px"} fontWeight={700}>
                Location: {selectedEntryData?.location?.locationName || "N/A"}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              gap={{ xs: "68px", md: 3 }}
            >
              <Typography fontSize={"20px"} fontWeight={700}>
                Stand #: {selectedEntryData?.stand || "N/A"}
              </Typography>
              <Typography fontSize={"20px"} fontWeight={700}>
                Status: {selectedEntryData?.status || "Open"}
              </Typography>
            </Box>
          </Box>
        )}

        <Box
          display={{ xs: "none", md: "flex" }}
          alignItems={"center"}
          gap={"3px"}
        >
          <Typography fontSize={"25px"} fontWeight={600}>
            Welcome,
            {user?.name
              ? user.name
                  .split(" ")
                  .map(
                    (word) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(" ")
              : ""}
          </Typography>
          {/* <KeyboardArrowDownIcon sx={{ width: "30px", height: "30px" }} /> */}
        </Box>
      </Box>

      <Box borderTop="2px solid #E0E0E0" sx={{ width: "100%" }}></Box>

      {/* DatePicker that appears directly */}
      <CustomDatePicker
        selectedDate={[selectedDate]}
        onChange={handleDateChange}
        showDatePicker={showDatePicker}
        onClose={handleCloseDatePicker}
        ref={calendarRef}
      />
    </CBox>
  );
};

export default Header;
