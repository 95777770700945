export const COLLECTIONS = {
  USERS: "users",
  LOCATIONS: "locations",
  USERLOCATIONS: "userLocations",
  ENTRIES: "entries",
  CARS: "cars",
  VIP_COUNTS: "vipCounts",
  CONDITIONAL_TIPS: "conditionalTips",
  VALIDATIONS: "validations",
  TIPS: "tips",
  TOTAL_TIPS_PER_DAY: "totalTipsPerDay",
  CC_TIPS_PAID: "ccTipsPaid",
  CREDIT_CARDS: "creditCards",
  DEPOSIT_AMOUNT: "depositAmount",
  SURCHARGES: "surcharges",
  BLUE_BAR: "blueBar",
  CASH_TURN_IN: "cashTurnIn",
  REVENUE: "revenue",
  GROSS_REVENUE: "grossRevenue",
  ENTRY_CALCULATIONS: "entryCalculations",
};
